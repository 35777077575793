@import "./src/assets/styles/index.scss";


.headersBody{
    // position: absolute;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 999;
    background: transparent;
}
.headersBody-hover{
    background:#fff;
}
.headers {
    width: var(--body-width);
    margin: auto;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    padding: 0;
    height: auto;
    &-login {
        height: var(--el-header-height);
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            height: 80%;
            width: auto;
        }
    }

}
