@import "./src/assets/styles/index.scss";

.menu {
    height: 100%;
    // display: inline-block;
    // width: 40%;
    // float: left;

    &-item {
        display: flex;
        height: 100%;
        box-sizing: border-box;
        margin: auto;
        align-items: center;
        text-align: justify;
        text-align-last: justify;

        >li {
            display: flex;
            align-items: center;
            font-size: 16px;
            height: 100%;
            padding: 0 12px;
            font-weight: 400;
            cursor: pointer;
            font-style: normal;
            white-space: nowrap;
            color: rgba(255, 255, 255, 0.7);

            a {
                color: #000;
                text-decoration: none;
            }



        }

        >li:last-child {
            margin-right: 0;
        }

        >li:nth-child(1) {
            margin-left: 0;
        }

        &-title {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            letter-spacing: 2px;

            >span {
                line-height: 60px;
            }

            >div {
                transition: all .2s ease;
                overflow: hidden;
            }
        }

        &-title::after {
            box-sizing: border-box;
            display: block;
            content: '';
            width: 0;
            background: #fff;
            transition: .4s ease;
            border-radius: 4px;
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
            height: 2px;
            bottom: -1px;
        }

        .is-active {
            font-size: 16px;
            color: #fff;
            font-weight: 600;
            align-items: center;
        }

        .is-active::after {
            width: 100%;
        }

    }
}

.menuInCollapse {
    width: 200px;
}

.el-menu--horizontal {
    border: none;
}

.el-menu-item {
    font-size: 18px;
    font-weight: 600;
}



.subMenu-item {
    position: absolute;
    top: var(--el-header-height);
    left: 0;
    width: 400%;
    flex-direction: column;

    ul {
        margin: 0;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    li {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: var(--font-size-small);
        height: 100%;
        font-weight: 400;
        cursor: pointer;
        font-style: normal;
        color: var(--el-text-color-regular);
        position: relative;

        div {
            width: fit-content;
            position: relative;
            padding: 16px 0;
        }
    }

    li:hover {
        font-weight: 600;
        // ::after {
        //     display: block;
        //     content: '';
        //     width: 100%;
        //     height: 2px;
        //     bottom: 0px;
        //     border-radius: 4px;
        //     position: absolute;
        //     background: gray;
        // }
    }

    &-active {
        font-weight: 600;
        // ::after {
        //     display: block;
        //     content: '';
        //     width: 100%;
        //     height: 2px;
        //     bottom: 0px;
        //     border-radius: 4px;
        //     position: absolute;
        //     background: #fff;
        // }
    }
}

.menuWhite {
    .menu-item {

        &-title {
            color: $logoColor !important;
        }

        .is-active {
            color: $logoColor !important;
        }

        .is-active::after {
            background: #000 !important;
            width: 100% !important;
        }
    }

}


.subMenu {
    width: 100%;
    position: fixed;
    top: var(--el-header-height);
    left: 0;
    background: #fff;
    // padding-bottom: 20px;
    // border-top: 1px solid rgb(209, 209, 209);

    ul {
        margin: 0;
        padding: 0;
        padding-bottom: 20px;
    }

    li {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 16px;
        height: 100%;
        font-weight: 400;
        cursor: pointer;
        font-style: normal;
        color: var(--el-text-color-regular);
        opacity: 0;
    }

    ::before {
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        position: relative;
        border-top: 1px solid rgb(211, 211, 211);
        padding-bottom: 10px;
    }
}

.sub {
    position: relative;
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
}
