@import "./src/assets/styles/index.scss";

.footer{
    width: 100%;
    display:flex;
    background: $footersColor;
    padding: 20px;
    padding-bottom: 40px;
    box-sizing: border-box;
    &-body{
        width: var(--body-width);
        margin: auto;
        color: rgba(255, 255, 255, .6);
        // color: rgba(196, 224, 250, 0.5);
        font-size: 24px;
        
        &-header{
            width: 100%;
            font-size: 24px;
            p{
                
                margin-bottom: 0;
            }
        }
        &-conten{
            font-size: 14px;
            padding-top:40px;
            color: rgba(255, 255, 255, .6);
            display: flex;
            flex-direction: column;
            p{
                // font-size: 16px;
                color: #fff;
                margin:2px 6px;
            }
            // &-green{
            //     font-weight: bold;
            // }
            >div{
                padding: 4px;
                display: flex;
                align-items: center;
                width: 100%;
                >div:nth-child(1){
                    width: 15%;
                    margin-right: 20px;
                }
                >div{
                    width: 50%;
                    text-align: right;
                }
            }
        }
        &-footer{
            font-size: 14px;
            margin-left: -50px;
            padding-top:40px;
            p{
                // font-size: 14px; 
                margin: 0;
                color: rgba(255, 255, 255, .6);
            }
            >div{
                padding: 4px;
                display: flex;
                align-items: center;
                width: 100%;
                >div:nth-child(1){
                    width: 14%;
                    margin-right: 20px;
                }
                >div{
                    width: 50%;
                    text-align: right;
                }
            }
        }
    }
    
}
