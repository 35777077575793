@import "./src/assets/styles/index.scss";

.layouts {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .el-row{
        height: calc(100% - 68px);
    }
    .el-col{
        position: relative;
    }
}


